import { produce } from 'immer';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, createSelector, Selector } from '@ngxs/store';
import {
  AddCatalogueProductToCart,
  GetAllProducts,
  GetCategories,
  GetProduct,
  GetProductBySku,
  GetProducts,
  RemoveCatalogueProductToCart,
  UpdateCatalogueProductCartQuanity,
} from './catalogue.actions';
import { ICatalogueCategory } from '../models/categories.model';
import { CatalogueService } from '../services/catalogue.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ICatalogue, ICatalogueCart, ICatalogueMetadata, ICataloguePromotion, ICatalogueResult } from '../models/catalogue.model';

export class CatalogueStateModel {
  public categories: ICatalogueCategory[];
  public selectedProduct: ICatalogue;
  public products: ICatalogue[]; //used for the main products list on the catalogues-products page (filtering too)
  public allProducts: ICatalogue[];
  public productsMetadata: ICatalogueMetadata;
  public productsCart: ICatalogueCart[]; //used for adding products to a cart in order to calculate products pricing
  public areProductsLoading: boolean;
}

const defaults = {
  categories: [],
  selectedProduct: null,
  products: [],
  allProducts: [],
  productsCart: [],
  productsMetadata: null,
  areProductsLoading: true,
};

@State<CatalogueStateModel>({
  name: 'catalogue',
  defaults,
})
@Injectable()
export class CatalogueState {
  @Selector() public static getAllCategories(state: CatalogueStateModel) {
    return state.categories;
  }
  @Selector() public static getProducts(state: CatalogueStateModel) {
    return state.products;
  }
  @Selector() public static getSelectedCatalogueProduct(state: CatalogueStateModel) {
    return state.selectedProduct;
  }
  @Selector() public static getAllProducts(state: CatalogueStateModel) {
    return state.allProducts;
  }
  @Selector() public static getProductsMetadata(state: CatalogueStateModel) {
    return state.productsMetadata;
  }
  @Selector() public static areProductsLoading(state: CatalogueStateModel) {
    return state.areProductsLoading;
  }
  @Selector() public static getProductsCart(state: CatalogueStateModel) {
    return state.productsCart;
  }
  @Selector() public static getProductsCartCount(state: CatalogueStateModel) {
    return state.productsCart.length;
  }

  @Selector() static getPromotionByPromotionId(state: CatalogueStateModel) {
    return (promotionId: string): ICataloguePromotion => {
      for (const product of state.products) {
        const matchingPromotion = product.promotions.find((x) => x.promotionId === promotionId);
        if (matchingPromotion) {
          return matchingPromotion;
        }
      }
    };
  }

  constructor(private catalogueService: CatalogueService) {}

  @Action(GetCategories, { cancelUncompleted: true })
  public getCategories(ctx: StateContext<CatalogueStateModel>) {
    return this.catalogueService.getCategories().pipe(
      tap((categoriesArr: ICatalogueCategory[]) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.categories = categoriesArr;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetProducts, { cancelUncompleted: true })
  public getProducts(ctx: StateContext<CatalogueStateModel>, { filterSettings, isScrolling }: GetProducts) {
    return this.catalogueService.getCatalogueProducts(filterSettings).pipe(
      tap((result: ICatalogueResult) => {
        const currentState = ctx.getState();
        let newProducts = isScrolling ? [...currentState.products, ...result.results] : result.results;

        const newState: CatalogueStateModel = {
          ...currentState,
          products: newProducts,
          productsMetadata: result.metadata,
          areProductsLoading: false,
        };

        ctx.setState(newState);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetAllProducts)
  public async GetAllProducts(ctx: StateContext<CatalogueStateModel>, { filterSettings }: GetAllProducts) {
    const catalogueData = await this.catalogueService.getCatalogueProducts(filterSettings).toPromise();
    let catalogue: ICatalogue[] = catalogueData.results;

    filterSettings.pageIndex += 1;

    while (catalogue.length < catalogueData.metadata.totalRecords) {
      const res = await this.catalogueService.getCatalogueProducts(filterSettings).toPromise();
      catalogue = [...catalogue, ...res.results];

      filterSettings.pageIndex += 1;
    }

    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.allProducts = catalogue;
      }),
    );
  }

  @Action(GetProductBySku, { cancelUncompleted: true })
  public GetProductBySku(ctx: StateContext<CatalogueStateModel>, { filterSettings, billingCycle, commitment }: GetProductBySku) {
    return this.catalogueService.getCatalogueProducts(filterSettings).pipe(
      tap((result: ICatalogueResult) => {
        const state = produce(ctx.getState(), (draft) => {
          const product = result.results.find((x) => x.product.billingTerm == billingCycle && x.product.recursionTerm == commitment);
          draft.selectedProduct = product;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetProduct, { cancelUncompleted: true })
  public GetProduct(ctx: StateContext<CatalogueStateModel>, { productId }: GetProduct) {
    return this.catalogueService.getCatalogueProduct(productId).pipe(
      tap((product) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.selectedProduct = product;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(AddCatalogueProductToCart, { cancelUncompleted: true })
  public addCatalogueProductToCart(ctx: StateContext<CatalogueStateModel>, { product }: AddCatalogueProductToCart) {
    const state = produce(ctx.getState(), (draft) => {
      const productIndex = draft.productsCart.findIndex((x) => x.catalogue.product.id == product.catalogue.product.id);

      if (productIndex != -1) {
        draft.productsCart[productIndex].quantity += 1;
      } else {
        draft.productsCart.push(product);
      }
    });

    ctx.setState(state);
  }

  @Action(RemoveCatalogueProductToCart, { cancelUncompleted: true })
  public removeCatalogueProductToCart(ctx: StateContext<CatalogueStateModel>, { productId }: RemoveCatalogueProductToCart) {
    const state = produce(ctx.getState(), (draft) => {
      const filteredProducts = draft.productsCart.filter((x) => x.catalogue.product.id != productId);
      draft.productsCart = filteredProducts;
    });

    ctx.setState(state);
  }

  @Action(UpdateCatalogueProductCartQuanity, { cancelUncompleted: true })
  public updateCatalogueProductCartQuanity(ctx: StateContext<CatalogueStateModel>, { productId, quantity }: UpdateCatalogueProductCartQuanity) {
    const state = produce(ctx.getState(), (draft) => {
      draft.productsCart.find((x) => x.catalogue.product.id == productId).quantity = quantity;
    });

    ctx.setState(state);
  }
}
