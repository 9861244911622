import { ICustomer } from 'src/modules/customer/models/customer.model';
import { ICatalogueCart, ICatalogueFilter, ICatalogueProduct } from '../models/catalogue.model';

export class GetCategories {
  static readonly type = '[Catalogue] GetCategories';
}

export class GetProducts {
  static readonly type = '[Catalogue] GetProducts';
  constructor(
    public filterSettings: ICatalogueFilter,
    public isScrolling: boolean,
  ) {}
}

export class GetProduct {
  static readonly type = '[Catalogue] GetProduct';
  constructor(
    public productId: ICatalogueProduct['id'],
    public customerId: ICustomer['id'],
  ) {}
}

export class GetProductBySku {
  static readonly type = '[Catalogue] GetProductBySku';
  constructor(
    public filterSettings: ICatalogueFilter,
    public billingCycle: any,
    public commitment: any,
  ) {}
}

export class GetAllProducts {
  static readonly type = '[Catalogue] GetAllProducts';
  constructor(public filterSettings: ICatalogueFilter) {}
}

export class AddCatalogueProductToCart {
  static readonly type = '[Catalogue] AddCatalogueProductToCart';
  constructor(public product: ICatalogueCart) {}
}

export class RemoveCatalogueProductToCart {
  static readonly type = '[Catalogue] RemoveCatalogueProductToCart';
  constructor(public productId: ICatalogueProduct['id']) {}
}

export class UpdateCatalogueProductCartQuanity {
  static readonly type = '[Catalogue] UpdateCatalogueProductCartQuanity';
  constructor(
    public productId: string,
    public quantity: number,
  ) {}
}
